<template>
    <div class="container pt-5">
      <div class="col height-self-center">
        <div class="col-sm-12 text-center align-self-center">
          <div class="iq-error position-relative error-message-container">
            <h2 class="mb-1">404 | This page cannot be found.</h2>
            <p>Sorry, the requested page does not exist.</p>
            <button @click="$router.go(-1)" class="btn btn-primary mt-3"><i class="ri-home-4-line"></i>Go Back</button>
            <p>If you think something is missing that should be here, please <router-link to="/contact">contact us</router-link>. Thank you!</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { core } from '../../config/pluginInit'
  
  export default {
    name: 'not-found',
    mounted () {
      core.index()
    },
  }
  </script>
  <style scoped>
  .error-message-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  p {
    font-weight: 500;
    font-size: large;
  }
  button {
    margin-bottom: 1em;
    font-size: large;
  }
  </style>
  